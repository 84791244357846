import classNames from "components/ui/classNames";
import getYear from "date-fns/getYear";
import { preserveOnlyUtmParameters } from "lib/analytics/utm";
import notUndefinedOrNull from "lib/utils/notUndefinedOrNull";
import { InstagramIcon, LinkedinIcon, YoutubeIcon } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import type { ParsedUrlQuery } from "querystring";
import { useIntl } from "react-intl";
import Disclaimers from "components/landing/Disclaimers";
import Fundamentei from "../branding/Fundamentei";

export default function Footer() {
  const intl = useIntl();
  const router = useRouter();
  const hrefForPathname = (pathname: string) => ({
    pathname,
    query: preserveOnlyUtmParameters(router?.query),
  });
  const links: {
    slug: string;
    links: {
      href:
        | string
        | {
            pathname: string;
            query: ParsedUrlQuery;
          };
      rel?: string;
      target?: string;
      label: string;
    }[];
  }[] = [
    {
      slug: intl.formatMessage({ defaultMessage: "Links", id: "qCcwo3" }),
      links: [
        {
          href: hrefForPathname("/premium"),
          label: intl.formatMessage({ defaultMessage: "Premium", id: "C5xzTC" }),
        },
        intl.locale === "pt-BR"
          ? {
              href: hrefForPathname("/black"),
              label: intl.formatMessage({ defaultMessage: "Black", id: "XjGbuY" }),
            }
          : null,
        intl.locale === "pt-BR"
          ? {
              href: hrefForPathname("/fatos-relevantes"),
              label: intl.formatMessage({ defaultMessage: "Fatos Relevantes", id: "xNV3AE" }),
            }
          : null,
        {
          href: hrefForPathname("/financials"),
          label: intl.formatMessage({ defaultMessage: "Balanços", id: "wauBuH" }),
        },
        {
          href: hrefForPathname("/compare"),
          label: intl.formatMessage({ defaultMessage: "Comparativo", id: "QNZcX0" }),
        },
        intl.locale === "pt-BR"
          ? {
              href: "https://linktr.ee/guiadeir",
              rel: "noopener noreferrer",
              target: "_blank",
              label: intl.formatMessage({ defaultMessage: "Imposto de Renda (IRPF)", id: "kpZwvx" }),
            }
          : null,
        {
          href: hrefForPathname("/events-calendar"),
          label: intl.formatMessage({ defaultMessage: "Agenda", id: "S7IK4I" }),
        },
        intl.locale === "pt-BR"
          ? {
              href: "/sitemap?filterBy=BRAZILIAN_COMPANY",
              label: intl.formatMessage({ defaultMessage: "Todas Ações", id: "nycezX" }),
            }
          : null,
        intl.locale === "pt-BR"
          ? {
              href: "/sitemap?filterBy=BRAZILIAN_REAL_ESTATE_FUND",
              label: intl.formatMessage({ defaultMessage: "Todos FIIs", id: "PFxgpF" }),
            }
          : null,
        {
          href: "/sitemap?filterBy=AMERICAN_COMPANY",
          label: intl.formatMessage({ defaultMessage: "Todas Stocks", id: "6ocUM1" }),
        },
        {
          href: "/sitemap?filterBy=AMERICAN_REIT",
          label: intl.formatMessage({ defaultMessage: "Todos REITs", id: "2PxUSd" }),
        },
      ].filter(notUndefinedOrNull),
    },
    {
      slug: intl.formatMessage({ defaultMessage: "A Empresa", id: "EuPlLp" }),
      links: [
        {
          href: "https://help.fundamentei.com/pt-BR/collections/5579574-atualizacoes",
          rel: "noopener noreferrer",
          target: "_blank",
          label: intl.formatMessage({ defaultMessage: "Novidades", id: "cOUMGL" }),
        },
        {
          href: "mailto:contato@fundamentei.com",
          label: intl.formatMessage({ defaultMessage: "Contato", id: "SgXTSK" }),
        },
        {
          href: "/privacy",
          label: intl.formatMessage({ defaultMessage: "Privacidade", id: "LN4JSv" }),
        },
        {
          href: "/payments-and-refunds",
          label: intl.formatMessage({ defaultMessage: "Pagamentos & reembolsos", id: "2eoh+z" }),
        },
        {
          href: "/terms",
          label: intl.formatMessage({ defaultMessage: "Termos de Uso", id: "6FD7WZ" }),
        },
        {
          href: "/anti-piracy",
          label: intl.formatMessage({ defaultMessage: "Política Anti-Pirataria", id: "hnknBu" }),
        },
        {
          href: "/anti-scraping",
          label: intl.formatMessage({ defaultMessage: "Política Anti-Scraping", id: "ERE+yY" }),
        },
        {
          href: "https://docs.google.com/document/d/e/2PACX-1vR5LJfZfLPYP99dty9jD2UHdGz9mTJ0Ly06ClzGJd-_ZwqgN86LcHal0U16Lm98XEmcU9Bfowd8zBFH/pub",
          rel: "noopener noreferrer",
          target: "_blank",
          label: intl.formatMessage({ defaultMessage: "Compliance", id: "NIi2K2" }),
        },
      ],
    },
    {
      slug: intl.formatMessage({ defaultMessage: "Desenvolvedores", id: "PQ54iX" }),
      links: [
        {
          href: "https://github.com/fundamentei",
          rel: "noopener noreferrer",
          target: "_blank",
          label: "GitHub",
        },
        {
          href: "https://stackshare.io/fundamentei/fundamentei",
          rel: "noopener noreferrer",
          target: "_blank",
          label: "StackShare",
        },
      ],
    },
  ];

  return (
    <footer className="space-y-12 border-t bg-slate-50 py-16">
      <div className={classNames("mx-auto w-full max-w-7xl px-6 xl:px-0")}>
        <div className="grid gap-8 sm:grid-cols-12">
          <div className="flex flex-col gap-4 sm:col-span-6 md:col-span-3 lg:col-span-6">
            <Link href="/" aria-label="Página inicial" className="text-zinc-500 focus:outline-none">
              <Fundamentei fill="currentColor" width={39} height={37} />
            </Link>

            <span className="grow text-sm text-zinc-500">© 2019 — {getYear(new Date())} Fundamentei</span>

            <ul className="flex space-x-4">
              <li>
                <a
                  href="https://www.youtube.com/channel/UC72WcGU4HpVposjNHlP3FRg"
                  title="YouTube"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#829ab1] no-underline focus:outline-none"
                >
                  <YoutubeIcon size={20} />
                </a>
              </li>

              <li>
                <a
                  href="https://instagram.com/fundamentei"
                  title="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#829ab1] no-underline focus:outline-none"
                >
                  <InstagramIcon size={20} />
                </a>
              </li>

              <li>
                <a
                  href="https://www.linkedin.com/company/fundamentei"
                  title="LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#829ab1] no-underline focus:outline-none"
                >
                  <LinkedinIcon size={20} />
                </a>
              </li>
            </ul>
          </div>

          {links.map((node) => (
            <div key={node.slug} className="space-y-2 sm:col-span-6 md:col-span-3 lg:col-span-2">
              <h4 className="text-sm font-bold text-slate-800">{node.slug}</h4>

              <ul className="space-y-2 text-sm">
                {node.links.map((link) => (
                  <li key={link.label}>
                    <Link
                      href={link.href}
                      rel={link.rel}
                      target={link.target}
                      prefetch={false}
                      className="text-slate-600 hover:font-medium hover:text-blue-600"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <hr className="border-b-1 mx-auto w-full max-w-7xl border-slate-200" />
      <Disclaimers />
    </footer>
  );
}
