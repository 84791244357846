/* eslint-disable react/no-unescaped-entities */
import getYear from "date-fns/getYear";
import { FormattedMessage } from "react-intl";

export default function Disclaimers() {
  const copyright = <>© {getYear(new Date())}</>;
  const finchat = (
    <a
      href="https://finchat.io/"
      target="_blank"
      rel="noopener noreferrer"
      className="font-medium text-slate-600 hover:underline hover:underline-offset-4"
    >
      Finchat ("Stratosphere Technology Inc")
    </a>
  );
  const intrinio = (
    <a
      href="https://intrinio.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="font-medium text-slate-600 hover:underline hover:underline-offset-4"
    >
      Intrinio Inc.
    </a>
  );
  const enfoque = (
    <a
      href="https://www.enfoque.com.br/"
      target="_blank"
      rel="noopener noreferrer"
      className="font-medium text-slate-600 hover:underline hover:underline-offset-4"
    >
      Enfoque Informações Financeiras LTDA
    </a>
  );
  const morningstar = (
    <a
      href="https://morningstar.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="font-medium text-slate-600 hover:underline hover:underline-offset-4"
    >
      Morningstar
    </a>
  );

  return (
    <section className="mx-auto grid max-w-7xl justify-center px-4 text-center xl:w-full xl:px-0">
      <p className="m-0 text-sm font-normal tracking-wide text-zinc-500 lg:text-justify lg:leading-relaxed">
        <FormattedMessage
          defaultMessage={`
<strong>Disclaimers:</strong> {copyright} {morningstar} - Os dados fundamentalistas de empresas Americanas ("Stocks / REITs") são fornecidos
pela Morningstar, atualizados diariamente. KPIs de empresas Americanas ("Stocks / REITs") são fornecidos pelo {copyright} {finchat},
cotações  de ativos Brasileiros ("Ações / FIIs") são fornecidos pela {copyright} {enfoque}, cotações de ativos
Americanos ("Stocks / REITs") são fornecidos pela {copyright} {intrinio} e atualizados diariamente. Dados de moedas (Forex) são fornecidos
pela {copyright} apilayer Data Products GmbH ("Currencylayer"), atualizados diariamente. Outros dados embutidos nas páginas das empresas tem
origem direta dos sistemas dos órgãos regulatórios como CVM ("Comissão de Valores Mobiliários") /
SEC ("U.S. Securities and Exchange Commission"), como também podem ser obtidos de comunicados oficiais das empresas em questão. O
Fundamentei não se responsabiliza pela precisão/completude ou integridade das informações apresentadas no site ou em qualquer um de
seus produtos. O Fundamentei também não se responsabiliza por quaisquer danos ou prejuízos decorrentes do uso dessas informações.
          `}
          id="KNy9I3"
          values={{
            // eslint-disable-next-line react/no-unstable-nested-components
            strong: (children) => <strong>{children}</strong>,
            copyright,

            finchat,
            intrinio,
            enfoque,
            morningstar,
          }}
        />
      </p>
    </section>
  );
}
